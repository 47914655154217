@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600);

* {
    font-family: 'Titillium Web';
}

.top-spacing{
    height: 0;
}
@media (min-width: 768px) { 
.top-spacing{
    height: 40px;
}
}

.AppCard{
    -webkit-box-shadow: 0px 0px 10px 0px rgba(133,133,133,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(133,133,133,1);
    box-shadow: 0px 0px 10px 0px rgba(133,133,133,1);

    padding-left: 0;
    padding-right: 0;

    background: rgb(51,164,237);
    background: linear-gradient(0deg, rgba(51,164,237,1) 0%, rgba(34,122,211,1) 100%);
}



.LatestTemp{
    min-height: 150px;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    color: #FFF;
}

.ChartContainer
{
    height: 400px;
}

h1{
    padding-top:24px;
    min-height: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    color: #FFF;
}



.x-axis path, .x-axis line{
    stroke: #FFF;
}
 .x-axis text{

    fill: #FFF;
}